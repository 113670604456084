import React, { useState, useEffect } from 'react';
import './EditFormsModal.css';
import config from '../config';
import Swal from 'sweetalert2';
import { useUser } from '../App'; // Import useUser hook to get the user context

const EditFormsModal = ({ isOpen, onClose, onSave, form, refreshForms }) => {
  const { user } = useUser(); // Get the user object from context
  const token = user.token; // Get the JWT token from the user object

  const [formId, setFormId] = useState(null);
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    if (form) {
      setFormId(form.id);
      setIsActive(form.isActive === 'Yes' || form.isActive === true ? 'Yes' : 'No');
    }
  }, [form]);

  const handleIsActiveChange = (e) => {
    setIsActive(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      formId: parseInt(formId, 10), // Ensure formId is an integer
      isActive: isActive === 'Yes',
    };

    console.log('Submitting form data:', payload); // Debugging log

    const response = await fetch(`${config.API_BASE_URL}/form/editForm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Add the JWT token in the headers
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const res = await response.json();
      Swal.fire({
          title: 'Success!',
          text: 'Form updated successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
      });
      onSave(res.data.forms); // Correctly update the state
      onClose();
      refreshForms(); // Fetch the latest forms data
    } else {
      Swal.fire({
                title: 'Error!',
                text: 'Error updating form. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Edit Form</h2>
        <button className="close-button" onClick={onClose}>×</button>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="isActive">Is Active</label>
            <select
              id="isActive"
              name="isActive"
              value={isActive}
              onChange={handleIsActiveChange}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="button-group">
            <button type="button" onClick={onClose}>CANCEL</button>
            <button type="submit">SAVE</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditFormsModal;
