import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/style.css';
import { useUser } from '../App'; // Import the custom hook from App.js
import config from '../config';
import Swal from 'sweetalert2';

function ReportsHome() {
  const navigate = useNavigate();
  const { user } = useUser(); // Get the user object from context
  const token = user.token; // Get the JWT token from the user object
  const companyId = user.companyId; // to store the current userId and pass in query Param for updated API calls
  const [forms, setForms] = useState([]); // State to hold the list of forms
  const [loading, setLoading] = useState(false); // State to manage loading spinner
  const [isLoading, setIsLoading] = useState(true); // For Loading API data state

  // Function to fetch forms from the API
  const fetchForms = useCallback(async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/form`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}` // Add the JWT token in the headers
        }
      });
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          setForms(data.data.forms);
        } else {
          console.error('Failed to fetch forms:', data.error);
        }
      } else {
        console.error('Failed to fetch forms:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching forms:', error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching data
    }
  }, [companyId, token]); // Add token to the dependency array

  // Fetch forms when the component mounts
  useEffect(() => {
    fetchForms();
  }, [fetchForms]);

  // Function to handle each card's button click
  const handleButtonClick = async (formId) => {
    setLoading(true);
    try {
      const response = await fetch(`${config.API_BASE_URL}/form/reports?formId=${formId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}` // Add the JWT token in the headers
        }
      });
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          navigate('/showReports', { state: { formData: data.data.formData, formId } });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Failed to fetch reports: ' + data.error,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } else {
        Swal.fire({
          title: 'No Data',
          text: 'Failed to fetch report as there is no data available..!',
          icon: 'info',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Error fetching reports: ' + error,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      //console.log("error-> ", error)
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the Refresh button click
  const handleRefreshClick = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.API_BASE_URL}/formSync/refresh`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}` // Add the JWT token in the headers
        }
      });
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          fetchForms(); // Refresh the forms list after a successful refresh
          Swal.fire({
            title: 'Success',
            text: 'Forms refreshed successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Failed to refresh forms: ' + data.error,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to refresh forms: ' + response.statusText,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Error refreshing forms: ' + error,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const handleViewAllReportsClick = () => {
    navigate('/allReports'); // Adjust the path to your all reports page
  };

  return (
    <>
      <div className="container mt-3">
        {isLoading ? (
          <div>
            <p>Loading...</p>
          </div>
        ) : forms.length === 0 ? (
          <p className="text-danger font-weight-bold">No Reports found for this Company. Please contact admin!</p>
        ) : (
          <>
            {loading && (
              <div className="loading-modal">
                <div className="loading-spinner"></div>
              </div>
            )}
            <div className="row mb-3">
              <div className="col text-right">
                <button className="jotFormBackButton" onClick={handleRefreshClick}>Refresh</button>
              </div>
            </div>
            <div className="row">
              {forms.map((form) => (
                <div className="col-md-4 mb-4" key={form.id}>
                  <div className="card h-100" onClick={() => handleButtonClick(form.id)}>
                    <div className="card-body">
                      <h5 className="card-title text-center font-weight-bold" style={{ fontSize: '1.5rem' }}>
                        {form.formName + ' Report'}
                      </h5>
                      {/* Remove the text and button */}
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </>
        )
        }
        {<div className="text-center mt-4">
          <button className="btn-view-all-reports" onClick={handleViewAllReportsClick}>View All Reports</button>
        </div>}
      </div>
    </>
  );
}

export default ReportsHome;
