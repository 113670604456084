import React, { useEffect, useState } from 'react';
import './Management.css';
import AddUserModal from './AddUserModal';
import AddVehicleModal from './AddVehicleModal';
import EditFormsModal from './EditFormsModal';
import EditVehicleModal from './EditVehicleModal';
import EditUserModal from './EditUserModal';
import Swal from 'sweetalert2';
import { useUser } from '../App';
import config from '../config';

const Management = () => {
  const [data, setData] = useState({ users: [], vehicles: [], forms: [], billing: [] });
  const [keysToShow, setKeysToShow] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTable, setActiveTable] = useState('users');
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);
  const [isEditFormsModalOpen, setIsEditFormsModalOpen] = useState(false);
  const [isEditVehicleModalOpen, setIsEditVehicleModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const [isPaymentSetup, setIsPaymentSetup] = useState(false);
  const { user } = useUser();
  const token = user.token;
  const [payableAmount, setPayableAmount] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      let response;
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      if (activeTable === 'users') {
        response = await fetch(`${config.API_BASE_URL}/user/getUsersbyCompany`, {
          method: 'GET',
          headers
        });
      } else if (activeTable === 'vehicles') {
        response = await fetch(`${config.API_BASE_URL}/vehicle`, {
          method: 'GET',
          headers
        });
      } else if (activeTable === 'forms') {
        response = await fetch(`${config.API_BASE_URL}/form/getManageForms`, {
          method: 'GET',
          headers
        });
      } else if (activeTable === 'billing') {
        response = await fetch(`${config.API_BASE_URL}/billing/getBillsbyCompany`, {
          method: 'GET',
          headers
        });
        setIsCheckboxChecked(false);
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (activeTable === 'users') {
        const processedData = data.data.map(user => ({
          ...user,
          name: `${user.firstName || ''} ${user.middleName || ''} ${user.lastName || ''}`.trim() || '',
          isActive: user.isActive ? 'Yes' : 'No'
        }));
        setData(prevData => ({ ...prevData, users: processedData }));
        setKeysToShow(['name', 'userName', 'email', 'role', 'isActive']);
      } else if (activeTable === 'vehicles') {
        const vehiclesData = data.data.vehicles.map(vehicle => ({
          ...vehicle,
          isActive: vehicle.isActive ? 'Yes' : 'No'
        }));
        setData(prevData => ({ ...prevData, vehicles: vehiclesData }));
        setKeysToShow(['vin', 'type', 'engine', 'vehicleNumber', 'description', 'isActive']);
      } else if (activeTable === 'forms') {
        const formsData = data.data.forms.map(form => ({
          ...form,
          isActive: form.isActive ? 'Yes' : 'No'
        }));
        setData(prevData => ({ ...prevData, forms: formsData }));
        setKeysToShow(['formName', 'isActive']);
      } else if (activeTable === 'billing') {
        const billingData = data.data.map(bill => ({
          ...bill,
          isBillPaid: bill.isBillPaid ? 'Yes' : 'No',
          isActive: bill.isActive ? 'Yes' : 'No'
        }));
        setData(prevData => ({ ...prevData, billing: billingData }));
        setKeysToShow(['vehiclesCount', 'usersCount', 'pricePerUser', 'pricePerVehicle', 'totalAmount', 'month', 'isBillPaid']);

        const totalPayableAmount = billingData.reduce((total, bill) => {
          if (bill.isBillPaid === 'No') {
            return total + bill.totalAmount;
          }
          return total;
        }, 0);

        setPayableAmount(totalPayableAmount);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error loading the data:', error);
      setLoading(false);
    }
  };

  const handleCheckboxChange = async (e) => {
    setIsCheckboxChecked(e.target.checked);
    if(e.target.checked)
    {
      try {
        const response = await fetch(`${config.API_BASE_URL}/billing/checkPaymentSetup`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.data) {
          Swal.fire({
            title: 'One Payment Method Added',
            text: 'Proceed to add another payment method.',
            icon: 'info',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error checking payment setup:', error);
        Swal.fire({
          title: 'Error',
          text: 'There was an error checking the payment setup.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        setIsCheckboxDisabled(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeTable, token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = data[activeTable].filter(item =>
    keysToShow.some(key =>
      String(item[key]).toLowerCase().includes(searchTerm)
    )
  ).filter(item => activeTable !== 'billing' || item.isActive === 'Yes');

  const handleAddUser = () => {
    fetchData();
  };

  const handleAddVehicle = () => {
    fetchData();
  };

  const handleEditForm = (updatedForm) => {
    setData(prevData => ({
      ...prevData,
      forms: prevData.forms.map(form =>
        form.id === updatedForm.id
          ? { ...updatedForm, isActive: updatedForm.isActive ? 'Yes' : 'No' }
          : form
      )
    }));
  };

  const handleEditVehicle = (updatedVehicle) => {
    setData(prevData => ({
      ...prevData,
      vehicles: prevData.vehicles.map(vehicle =>
        vehicle.id === updatedVehicle.id
          ? { ...updatedVehicle, isActive: updatedVehicle.isActive ? 'Yes' : 'No' }
          : vehicle
      )
    }));
  };

  const handleEditUser = (updatedUser) => {
    setData(prevData => ({
      ...prevData,
      users: prevData.users.map(user =>
        user.id === updatedUser.id
          ? { ...updatedUser, isActive: updatedUser.isActive ? 'Yes' : 'No' }
          : user
      )
    }));
  };

  const openEditFormsModal = (form) => {
    setSelectedForm(form);
    setIsEditFormsModalOpen(true);
  };

  const openEditVehicleModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsEditVehicleModalOpen(true);
  };

  const openEditUserModal = (user) => {
    setSelectedUser(user);
    setIsEditUserModalOpen(true);
  };

  const handleAddPaymentMethod = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/payment/createCheckoutSession`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.status === 'success') {
        window.location.href = data.url;
      } else {
        console.error('Payment session creation failed:', data);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <div className="management">
      <div className="slider-container">
        <div className="slider">
          <button onClick={() => setActiveTable('users')} className={activeTable === 'users' ? 'active' : ''}>Users</button>
          <button onClick={() => setActiveTable('vehicles')} className={activeTable === 'vehicles' ? 'active' : ''}>Vehicles</button>
          <button onClick={() => setActiveTable('forms')} className={activeTable === 'forms' ? 'active' : ''}>Forms</button>
          <button onClick={() => setActiveTable('billing')} className={activeTable === 'billing' ? 'active' : ''}>Billing</button>
          <div className="slider-highlight"></div>
        </div>
      </div>
      <div className="controls">
        <input
          type="text"
          id="searchInput"
          placeholder={`Search ${activeTable}`}
          value={searchTerm}
          onChange={handleSearch}
        />
        {activeTable === 'users' && (
          <button onClick={() => setIsAddUserModalOpen(true)} className="add-button">Add User</button>
        )}
        {activeTable === 'vehicles' && (
          <button onClick={() => setIsAddVehicleModalOpen(true)} className="add-button">Add Vehicle</button>
        )}
      </div>
      <div id="tableContainer" className="table-container">
        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
        <div className="table-wrapper">
          <table className="data-table">
            <thead>
              <tr>
                {keysToShow.map(key => (
                  <th key={key}>{key}</th>
                ))}
                {(activeTable === 'forms' || activeTable === 'vehicles' || activeTable === 'users') && <th></th>}
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 ? (
                <tr>
                  <td colSpan={keysToShow.length + ((activeTable === 'forms' || activeTable === 'vehicles' || activeTable === 'users') ? 1 : 0)} style={{ textAlign: 'center' }}>No data available</td>
                </tr>
              ) : (
                filteredData.map((row, index) => (
                  row && (
                    <tr key={index}>
                      {keysToShow.map(key => (
                        <td key={key}>{row[key]}</td>
                      ))}
                      {activeTable === 'forms' && (
                        <td>
                          <span onClick={() => openEditFormsModal(row)} style={{ cursor: 'pointer' }}>&#9998;</span>
                        </td>
                      )}
                      {activeTable === 'vehicles' && (
                        <td>
                          <span onClick={() => openEditVehicleModal(row)} style={{ cursor: 'pointer' }}>&#9998;</span>
                        </td>
                      )}
                      {activeTable === 'users' && (
                        <td>
                          <span onClick={() => openEditUserModal(row)} style={{ cursor: 'pointer' }}>&#9998;</span>
                        </td>
                      )}
                    </tr>
                  )
                ))
              )}
            </tbody>
          </table>
        </div>
        )}
        {activeTable === 'billing' && (
          <div className="billing-section">
            {filteredData.length > 0 && (
              <div className="payable-amount">
                Total Payable amount: <span>${payableAmount}</span>
              </div>
            )}
            {!isPaymentSetup && (
              <div>
                <div className="recurring-payment">
                  <input
                    type="checkbox"
                    id="recurringPayment"
                    checked={isCheckboxChecked}
                    disabled={isCheckboxDisabled}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="recurringPayment"> I certify for recurring payment</label>
                </div>
                <button
                  className={`add-button ${isCheckboxChecked ? '' : 'disabled'}`}
                  disabled={!isCheckboxChecked}
                  onClick={handleAddPaymentMethod}
                >
                  Add Payment Method
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <AddUserModal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        onSave={handleAddUser}
      />
      <AddVehicleModal
        isOpen={isAddVehicleModalOpen}
        onClose={() => setIsAddVehicleModalOpen(false)}
        onSave={handleAddVehicle}
      />
      <EditFormsModal
        isOpen={isEditFormsModalOpen}
        onClose={() => setIsEditFormsModalOpen(false)}
        onSave={handleEditForm}
        form={selectedForm}
        refreshForms={fetchData}
      />
      <EditVehicleModal
        isOpen={isEditVehicleModalOpen}
        onClose={() => setIsEditVehicleModalOpen(false)}
        onSave={handleEditVehicle}
        vehicle={selectedVehicle}
      />
      <EditUserModal
        isOpen={isEditUserModalOpen}
        onClose={() => setIsEditUserModalOpen(false)}
        onSave={handleEditUser}
        user={selectedUser}
      />
    </div>
  );
};

export default Management;
