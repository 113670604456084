import React, { useState, useEffect } from 'react';
import './AddVehicleModal.css';
import { useUser } from '../App';
import config from '../config';
import Swal from 'sweetalert2';

const AddVehicleModal = ({ isOpen, onClose, onSave }) => {
  const { user } = useUser(); // Get the user object from context
  const token = user.token; // Get the JWT token from the user object
  const companyId = user.companyId; // Get the companyId from the user context

  const [vin, setVin] = useState('');
  const [type, setType] = useState('');
  const [engine, setEngine] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [description, setDescription] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Reset form fields when the modal is opened
      setVin('');
      setType('');
      setEngine('');
      setVehicleNumber('');
      setDescription('');
      setFormDisabled(false);
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'vin') setVin(value);
    if (name === 'type') setType(value);
    if (name === 'engine') setEngine(value);
    if (name === 'vehicleNumber') setVehicleNumber(value);
    if (name === 'description') setDescription(value);
  };

  const fetchPostApi = (endpoint, jsonBody) => {
    return fetch(`${config.API_BASE_URL}` + endpoint, {
      body: JSON.stringify(jsonBody),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}` // Add the JWT token in the headers
      }
    })
    .then(async (res) => {
      const text = await res.text();
      try {
        const json = JSON.parse(text);
        if (!res.ok) {
          throw json;
        }
        return json;
      } catch (error) {
        if (!res.ok) {
          const errorMessage = text.match(/<pre>(.*?)<\/pre>/s)?.[1]?.split('<br>')[0] || 'Error saving vehicle. Please try again.';
          throw new Error(errorMessage);
        }
        throw error;
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisabled(true);

    const newVehicle = { vin, type, engine, companyId, vehicleNumber, description }; // Include companyId
    try {
      const res = await fetchPostApi('/vehicle/addVehicle', newVehicle);
      Swal.fire({
        title: 'Success!',
        text: 'A new Vehicle Details are Added Successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          onSave();
          onClose();
        }
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error!',
        text: error.message || 'Error saving vehicle. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      setFormDisabled(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add Vehicle</h2>
        <button className="close-button" onClick={onClose}>×</button>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="vin">VIN</label>
              <input
                type="text"
                id="vin"
                name="vin"
                value={vin}
                onChange={handleChange}
                disabled={formDisabled}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <input
                type="text"
                id="type"
                name="type"
                value={type}
                onChange={handleChange}
                disabled={formDisabled}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="engine">Engine</label>
              <input
                type="text"
                id="engine"
                name="engine"
                value={engine}
                onChange={handleChange}
                disabled={formDisabled}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="vehicleNumber">Vehicle Number</label>
              <input
                type="text"
                id="vehicleNumber"
                name="vehicleNumber"
                value={vehicleNumber}
                onChange={handleChange}
                disabled={formDisabled}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                id="description"
                name="description"
                value={description}
                onChange={handleChange}
                disabled={formDisabled}
                required
              />
            </div>
          </div>
          <div className="button-group">
            <button type="button" onClick={onClose} disabled={formDisabled}>CANCEL</button>
            <button type="submit" disabled={formDisabled}>SAVE</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVehicleModal;
