import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/style.css';

function Dashboard() {
    return (
        <div className="dashboard-container">
            <div className="dash-card">
                <Link to="/userForms">
                    <img src={'../images/form.png'} alt="Forms" className="icon" style={{ width: '140px' }}/>
                    <h3>Forms</h3>
                </Link>
            </div>
            <div className="dash-card">
                <Link to="/reportsHome">
                    <img src={'../images/report.png'} alt="Reports" className="icon" style={{ width: '170px' }}/>
                    <h3>Reports</h3>
                </Link>
            </div>
            <div className="dash-card">
                <Link to="/management">
                    <img src={'../images/management.png'} alt="Reports" className="icon" style={{ width: '170px' }}/>
                    <h3>Management</h3>
                </Link>
            </div>
        </div>
    );
}

export default Dashboard;