
const API_BASE_URLS = {
    development: 'http://localhost:8085',
    production: 'https://api.nixknows.com'
};


const config = {
    API_BASE_URL: API_BASE_URLS[process.env.REACT_APP_ENVIRONMENT] || 'https://api.nixknows.com',
    STREAMLIT_BASE_URL: 'https://visualize.nixknows.com'
};

export default config;



