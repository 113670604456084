import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom

function Banner() {
  const [mainText, setMainText] = useState('');
  const [subText, setSubText] = useState(null);
  const [bannerId, setBannerId] = useState('default-banner');
  const location = useLocation(); // Use useLocation to detect changes in the URL


  // Update banner content on initial render and whenever user changes
  useEffect(() => {

    
  // Function to update banner content based on user state
  const updateBannerContent = () => {

    const user = JSON.parse(localStorage.getItem('user')) || {};
    const currentPage = location.pathname;

     if (currentPage === '/' || currentPage === '/index') {
        setMainText("Unlocking your data");
        setSubText(<p>that's hidden in your vehicle</p>);
        // setBannerId("index-banner");
        setBannerId("");
    } else if (currentPage === '/dashboard') {
        setMainText(user ? <h4>Hello, {(user.firstName === null)?user.username:user.firstName}</h4>  : null);
        setSubText(<p>Welcome to Your Dashboard</p>);
        // setBannerId("reports-banner");
        setBannerId("");
    } else if (currentPage === '/register') {
        setMainText("Register");
        setSubText(<p>Have all your data in one location</p>);
        // setBannerId("registration-banner");
        setBannerId("");
    } else if (currentPage === '/login') {
        setMainText("Login");
        setSubText(<p>Have all your data in one location</p>);
        // setBannerId("login-banner");
        setBannerId("");
    } else if (currentPage.includes('/management')) {
        setMainText("Account Management");
        setSubText(<p>Manage your account details</p>);
        // setBannerId("account-banner");
        setBannerId("");
    } else if (currentPage.includes('/userForms')) {
      setMainText("Forms");
        setSubText(<p>Complete Your Forms with Ease</p>);
        // setBannerId("registration-banner");
        setBannerId("");
    } else if (currentPage.includes('/reportsHome')) {
      setMainText("Reports");
        setSubText(<p>View and Analyze all Your Reports with Ease</p>);
        // setBannerId("reports-banner");
        setBannerId("");
    } else {
      // Handle other pages or invalid URLs
      setMainText("Unlocking your data");
      setSubText(<p>that's hidden in your vehicle</p>);
      // setBannerId("index-banner");
      setBannerId("");
      // Alternatively, handle "Page Not Found"
      // setMainText('Page Not Found');
      // setSubText(null);
      // setBannerId("not-found-banner");
    }
  };

    updateBannerContent();
  }, [location]);

  return (
    <div id={bannerId} className="banner">
      <div className="banner-content">
        <div className="text-section">
          <h4>{mainText}</h4>
          {subText}
        </div>
      </div>
    </div>
  );
}

export default Banner;

