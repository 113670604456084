import React, { useEffect, useState } from 'react';
import './assets/css/Homepage.css';
import './assets/css/style.css';
import Marquee from 'react-marquee-slider';

const Homepage = () => {
  const [userContent, setUserContent] = useState('<h6>User</h6>');
  const [vehicleContent, setVehicleContent] = useState('<h6>Vehicles</h6>');

  useEffect(() => {
    setUserContent('<h6>User</h6>');
    setVehicleContent('<h6>Vehicles</h6>');
  }, []);

  return (
    <div className="container">
      <div className="section">
        <h4 className="title">OUR SERVICES</h4>
          <div className="offerings">
            <h5>Vehicle Emissions</h5>
            <img className="image" src="/images/local_shipping.png" alt="Vehicle" />
            <p>Real-time emissions data at your fingertips</p>
            <br></br>
            <p>Our data is stored on the cloud and presented to you in a customizable, easy-to-digest dashboard</p>
          </div>
          <div className="offerings">
            <h5>Regulatory</h5>
            <img className="image" src="/images/settings.png" alt="gearWheel" />
            <p>Reports built and formatted for all of your reporting needs</p>
            <br></br>
            <p>We provide the needed reporting metrics broken down for the relevant reports</p>
          </div>
          <div className="offerings">
            <h5>Third Party Integration</h5>
            <img className="image" src="/images/groups.png" alt="people" />
            <p>Integrate with other third-party systems and services so you have all your data in one place, simplifying your reporting needs</p>
          </div>
      </div>

      <div className="benefits">
        <h4 className="title">BENEFITS OF NIX</h4>
          <div className="benefitsList">
            <img className="image" src="/images/Device.png" alt="device" />
            <h5>ONE DEVICE</h5>
            <br></br>
            <p className="body1">Easily installed in less than 5 minutes</p>
          </div>
          <div className="benefitsList">
            <img className="image" src="/images/gettyimages-sustainability-1068050202.jpg" alt="sustainability" />
            <h5>ALL REQUIREMENTS MET</h5>
            <br></br>
            <p className="body1">Emissions data provided in the format required</p>
          </div>
          <div className="benefitsList">
            <img className="image" src="/images/auto_reporting.png" alt="auto reporting" />
            <h5>AUTOMATIC REPORTING</h5>
            <br></br>
            <p className="body1">Reports sent to you and regulators when you need them</p>
          </div>
      </div>

      <div className="cost">
        <h4 className="title">PRICING</h4>
        <p className="body1">Automatic Peace of Mind for a low monthly subscription</p>
        <br></br>
        <div className="devices">
            <p>The Device</p>
            <h6>$50/vehicle</h6>
            <p>One-time purchase.</p>
        </div>
          <h2>+</h2>
          <div className="plan">
            <p>Tier 1</p>
            <h6>$15/month</h6>
            <p className="subtext">Basic reporting and data collection</p>
          </div>


          <div className="plan">
            <p>Tier 2</p>
            <h6>$25/month</h6>
            <p className="subtext">Customized reporting and data collection</p>
          </div>

          <br></br><br></br>

          <div className="plan">
            <p>Tier 3</p>
            <h6>$45/month</h6>
            <p className="subtext">Tier 2 with custom integration, support, and management</p>
          </div>
      </div>

<div className="section">
      <div className="testimonials">
        <h4 className="title">Don't just take our word for it!</h4>
        <Marquee>
          <div className="quotes">
            <blockquote>
                <h6>This is going to make </h6>
                <h6>emissions reporting so </h6>
                <h6>much easier for us.</h6>
                <div className="blockquote-footer"> - Eric, The Kran Company</div>
            </blockquote>
          </div>

          <div className="quotes">
            <blockquote>
                <h6>We waste a lot of diesel on </h6>
                <h6>idling. This is going to help us</h6>
                <h6>cut back on that significantly.</h6>
                <div className="blockquote-footer"> - Scott, The Kran Company</div>
            </blockquote>
          </div>

          <div className="quotes">
            <blockquote>
                <h6>I like knowing what’s going on in</h6>
                <h6>my trucks. This enables me to know</h6>
                <h6>how much fuel my guys are using.</h6>
                <div className="blockquote-footer"> - Pepe, P&P Services LLC</div>
            </blockquote>
          </div>
        </Marquee>
      </div>
</div>

      <div className="section">
        <h4 className="title">TRUSTED PARTNERS</h4>
        <div className="partners">
          <img className="partner-logo" src="/images/Kran+Logo.png" alt="theKran.com" />
          <img className="partner-logo" src="/images/RectangleSmall.jpg" alt="partner" />
          <img className="partner-logo" src="/images/RectangleSmall.jpg" alt="partner" />
          <img className="partner-logo" src="/images/RectangleSmall.jpg" alt="partner" />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
