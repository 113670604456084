import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../App'; // Import the custom hook from App.js

function Header() {
  const { user, logout } = useUser(); // Get the user and logout function from context
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu
  const [headerDropdownOpen, setHeaderDropdownOpen] = useState(false); // State for header dropdown menu
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false); // State for profile dropdown menu
  const headerDropdownRef = useRef(null); // Ref for header dropdown
  const profileDropdownRef = useRef(null); // Ref for profile dropdown
  const mobileMenuRef = useRef(null); // Ref for mobile menu
  const navigate = useNavigate();

  const getProfilePath = (role) => {
    switch (role) {
      case 'admin':
        return "/adminProfile";
      case 'user':
        return "/userProfile";
      default:
        return "/userProfile";
    }
  };

  // Function to handle navigation to the login page
  const handleSignIn = () => {
    navigate("/login");
    setMobileMenuOpen(false); // Close the mobile menu after navigation
  };

  // Function to handle navigation to the registration page
  const handleSignUp = () => {
    navigate("/register");
    setMobileMenuOpen(false); // Close the mobile menu after navigation
  };

  // Function to handle user logout
  const handleSignOut = () => {
    logout(); // Call the logout function from context
    navigate("/");
    setMobileMenuOpen(false); // Close the mobile menu after logging out
  };

  // Function to toggle the mobile menu
  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setHeaderDropdownOpen(false); // Close header dropdown when mobile menu is toggled
    setProfileDropdownOpen(false); // Close profile dropdown when mobile menu is toggled
  };

  // Function to close dropdowns when clicking outside
  const handleOutsideClick = (event) => {
    if (!mobileMenuRef.current.contains(event.target)) {
      setMobileMenuOpen(false);
    }
    if (headerDropdownRef.current && !headerDropdownRef.current.contains(event.target)) {
      setHeaderDropdownOpen(false);
    }
    if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
      setProfileDropdownOpen(false);
    }
  };

  // Add event listener to handle outside clicks
  useEffect(() => {
    if (mobileMenuOpen || headerDropdownOpen || profileDropdownOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [mobileMenuOpen, headerDropdownOpen, profileDropdownOpen]);

  // Function to toggle the header dropdown menu
  const handleHeaderDropdownToggle = () => {
    setHeaderDropdownOpen(!headerDropdownOpen);
    setMobileMenuOpen(false); // Close mobile menu when header dropdown is toggled
  };

  // Function to toggle the profile dropdown menu
  const handleProfileDropdownToggle = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
    setHeaderDropdownOpen(false); // Close header dropdown when profile dropdown is toggled
    setMobileMenuOpen(false); // Close mobile menu when profile dropdown is toggled
  };

  return (
    <header>
      <nav id="user-profile" className="navbar">
        <div className="logo">
          <Link to="/">
            <img src="/images/Cleaned_Nix_Logo.png" alt="Logo" id="logo" />
          </Link>
        </div>
        <div className="nav-links-container">
          <ul className={`nav-links ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
            {!user &&
            <>
              <li><Link to="/index" onClick={() => setMobileMenuOpen(false)}>Home</Link></li>
              <li><Link to="/register" onClick={() => setMobileMenuOpen(false)}>Register</Link></li>
              <li><button className="sign-in" onClick={handleSignIn}><img src="/images/account_circle.png" alt="Blank-Profile" className="new-signIn-profile-img" />Sign In</button></li>
              {/* <li><button className="sign-up" onClick={handleSignUp}>Sign Up</button></li> */}
            </>
            }
            {user && (
              <>
                {user.userRole === 'admin' && (
                  <>
                    <li><Link to="/dashboard" onClick={() => setMobileMenuOpen(false)}>Dashboard</Link></li>
                  </>
                )}
                <li><Link to="/userForms" onClick={() => setMobileMenuOpen(false)}>Forms</Link></li>
                {user.userRole === 'admin' && (
                  <>
                    <li><Link to="/reportsHome" onClick={() => setMobileMenuOpen(false)}>Reports</Link></li>
                    <li><Link to="/management" onClick={() => setMobileMenuOpen(false)}>Management</Link></li>
                  </>
                )}
 
                {mobileMenuOpen ? (
                  <>
                    {/* /for options added to header from footer on login */}
                    <li><Link to="/docs/contact">Contact Us</Link></li>
                    <li><Link to="/underConstruction">Send Feedback</Link></li>
                    {/* /for options added to header from footer on login */}
                    {/* <li style={{display:"flex", alignItems:"center"}} > */}
                    <li style={{display:"flex"}} >
                      <img src={user.profileImg || "/images/account_circle2.png"} alt="Profile" className="profile-img" style={{marginRight:"5px"}} />
                      <Link to={getProfilePath(user.userRole)} onClick={() => setMobileMenuOpen(false)}>{user.username}</Link>
                    </li>
                    <li><button className="logout-button" onClick={handleSignOut} style={{ marginBottom: "10px", fontWeight: "bold" }}>Sign Out</button></li>
                  </>
                ) : (
                  <li className="user-info">
                    <div className="dropdown" ref={profileDropdownRef}>
                      <button className="dropbtn" onClick={handleProfileDropdownToggle}>
                          <img src={user.profileImg || "/images/account_circle2.png"} alt="Profile" className="profile-img" />
                          {user.username}
                      </button>

                  </div>
                  </li>
                )}
              </>
            )}
          </ul>
      </div>
        <div className="mobile-menu-toggle" onClick={handleMobileMenuToggle} ref={mobileMenuRef}>
        <span className="menu-icon">{mobileMenuOpen ? '⌄' : '≡'}</span>
        </div>
      </nav>
      {user && (
        <div className={`dropdown-content ${profileDropdownOpen ? 'show' : ''}`}>
          <ul>
              <li className="user-role">{user.userRole}</li> 
              <li className="destopDropOptions"><Link to={getProfilePath(user.userRole)} className="profile">Profile</Link></li>
              {/* /for options added to header from footer on login */}
              <li className="destopDropOptions"><Link to="/docs/contact" className="profile">Contact Us</Link></li>
              <li className="destopDropOptions"><Link to="/underConstruction" className="profile">Send Feedback</Link></li>
              {/* /for options added to header from footer on login */}
              <li><button className="logout-button" onClick={handleSignOut}>Sign Out</button></li>
          </ul>
        </div>
          )}
    </header>
  );
}

export default Header;
