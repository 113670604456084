import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


const JotForm = ({ formId, formTitle, formUrl }) => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.jotformEmbedHandler) {
        window.jotformEmbedHandler(
          `iframe[id='JotFormIFrame-${formId}']`,
          'https://form.jotform.com/'
        );
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [formId]);


  // Function to handle back button click
  const handleBackClick = () => {
    navigate('/userForms'); // Navigate back to FormsHome
  };

  // Pass nixformid and userid as url params to fill hidden field values in Jotforms 
  if (formUrl !== 'https://form.jotform.com/241275195641155') {   //Check if the form URL is not for Contact Us page
    const nixformid = formId; // get the id of form assigned in DB not jotformId
    const userid = location.state.uId; // Retrieve companyId from the navigation state

    // Function to append parameters to the form URL
      const appendParamsToUrl = (url, params) => {
      const urlObj = new URL(url);
      Object.keys(params).forEach(key => urlObj.searchParams.append(key, params[key]));
      return urlObj.toString();
      };
  
      // Construct the form URL with prefilled hidden fields
      formUrl = appendParamsToUrl(formUrl, { nixformid:nixformid, userid:userid });
      //console.log("jotform URL with params for hidden filed->", formUrl, nixformid, userid)
    }

  return (
    <div style={{backgroundColor: "#f3f3fe"}}>
      {location.pathname !== '/docs/contact' && ( // Conditionally render back button
        <button className="jotFormBackButton" onClick={handleBackClick}>
          Go Back
        </button>
      )}
      <iframe
        id={`JotFormIFrame-${formId}`}
        title={formTitle}
        onLoad={() => window.parent.scrollTo(0, 0)}
        src={formUrl}
        frameBorder="10"
        style={{ minWidth: '100%', maxWidth: '200%', height: '870px', border: 10 }}
        scrolling="no"
      />

    </div>
  );
};

export default JotForm;
