import React, { useState, useEffect } from 'react';
import './EditUserModal.css';
import config from '../config';
import Swal from 'sweetalert2';
import { useUser } from '../App'; // Import useUser hook to get the user context
import CryptoJS from 'crypto-js'; // addeed for reset to def password by admin

const EditUserModal = ({ isOpen, onClose, onSave, user }) => {
  const { user: currentUser } = useUser(); // Get the current user object from context
  const token = currentUser.token; // Get the JWT token from the current user object
  const secretKey = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef'); // 64 hex characters for AES-256 // addeed for reset to def password by admin


  const [userId, setUserId] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isResetPassword, setIsResetPassword] = useState(false); // New state to handle form switch  // addeed for reset to def password by admin
  const [defPassword, setDefPassword] = useState(null);
  const companyDefaultPassword = CryptoJS.AES.decrypt(currentUser.companyDefPass, secretKey, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8);
  const [loading, setLoading] = useState(false); // State to manage loading spinner // added to send email on successful reset password by admin


  useEffect(() => {
    if (user) {
      setUserId(user.id);
      setIsActive(user.isActive === 'Yes' ? 'Yes' : 'No');
      setDefPassword(companyDefaultPassword);
      if (!isOpen){
        setIsResetPassword(false); // Reset slider to first tab when modal is closed
      }

    }
  }, [user, isOpen, companyDefaultPassword]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const payload = {
      userId: userId,
      status: isActive === 'Yes'
    };

    const response = await fetch(`${config.API_BASE_URL}/user/editUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Add the JWT token in the headers
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      setLoading(false);
      const res = await response.json();
      Swal.fire({
        title: 'Success!',
        text: 'User status updated successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      onSave({ ...user, isActive: isActive === 'Yes' }); // Pass updated user data back to Management component
      onClose();
    } else {
      setLoading(false);
      const errorRes = await response.json();
      Swal.fire({
        title: 'Error!',
        text: `Error updating user status: ${errorRes.data || 'Please try again.'}`,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  // added to send email on successful reset password by admin
  const fetchPostApi = (endpoint, jsonBody) => {
    return fetch(`${config.API_BASE_URL}` + endpoint, {
      body: JSON.stringify(jsonBody),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}` // Add the JWT token in the headers
      }
    })
    .then(async (res) => {
      const text = await res.text();
      try {
        const json = JSON.parse(text);
        if (!res.ok) {
          throw json;
        }
        return json;
      } catch (error) {
        if (!res.ok) {
          const errorMessage = text.match(/<pre>(.*?)<\/pre>/s)?.[1]?.split('<br>')[0] || 'Error saving user. Please try again.';
          throw new Error(errorMessage);
        }
        throw error;
      }
    });
  }
  // added to send email on successful reset password by admin

// addeed for reset to def password by admin

  const handleResetPassword = async (e) => {
    setLoading(true);
    e.preventDefault();

    const payload = {
      userId: userId,
      companyDefaultPassword: CryptoJS.SHA256(defPassword).toString()
    };

    // console.log("Reset to def pass Admin Payload-> ", payload);

    try {  // added to send email on successful reset password by admin
    const response = await fetch(`${config.API_BASE_URL}/user/adminResetPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Add the JWT token in the headers
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const decryptedPassword = CryptoJS.AES.decrypt(currentUser.companyDefPass, secretKey, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8);
      
            // added to send email on successful reset password by admin
            const maildata = {
              userName: user.userName,
              email: user.email,
              role: user.role,
              companyId: user.companyId,
              firstName: user.firstName,
              lastName: user.lastName,
              password: currentUser.companyDefPass,
              addedByFirstName: currentUser.firstName, 
              addedByLastName: currentUser.lastName,  
              action: 'resetPassword' 
            };
      
            try {
              const mailres = await fetchPostApi('/user/sendEmail', maildata);
      
              if (mailres.status === 'success') {
                setLoading(false);
                Swal.fire({
                  title: 'Success!',
                  text: `Successfully reset to default Password: ${decryptedPassword} and email sent!`,
                  icon: 'success',
                  confirmButtonText: 'OK'
                }).then(() => {
                  onClose();
                });
              } else {
                throw new Error('The email was not sent.');
              }
            } catch (emailError) {
              setLoading(false);
              Swal.fire({
                title: 'Warning!',
                text: `Successfully reset to default Password: ${decryptedPassword}, but the email was not sent.`,
                icon: 'warning',
                confirmButtonText: 'OK'
              }).then(() => {
                onClose();
              });
            }
          } else {
            const errorRes = await response.json();
            Swal.fire({
              title: 'Error!',
              text: `Error resetting password: ${errorRes.data || 'Please try again.'}`,
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
        } catch (userError) {
          console.error(userError);
          Swal.fire({
            title: 'Error!',
            text: userError.message || 'Error resetting password. Please try again.',
            icon: 'error',
            confirmButtonText: 'Try Again'
          });
        }
        setLoading(false);
      };
       // added to send email on successful reset password by admin

// addeed for reset to def password by admin

  if (!isOpen) return null;

  return (
    // added to send email on successful reset password by admin
    <> 
    {loading && (
      <div className="loading-modal">
        <div className="loading-spinner"></div>
      </div>
    )}
    {/* // added to send email on successful reset password by admin */}
    <div className="modal-overlay">
      <div className="modal-content">
      <div className="newslider-container">
          <div className="newslider">
            <button
              onClick={() => setIsResetPassword(false)}
              className={!isResetPassword ? 'active' : ''}
            >
              Edit User Status
            </button>
            <button
              onClick={() => setIsResetPassword(true)}
              className={isResetPassword ? 'active' : ''}
            >
              Reset Password
            </button>
            <div className="newslider-highlight-edituser"></div>
          </div>
        </div>
        <button className="close-button" onClick={onClose}>×</button>
        {isResetPassword ? (
          <form onSubmit={handleResetPassword}>
            <p style={{fontSize:"14pt"}}>Do you want to reset this user's password to the default password?</p>
            <div className="button-group">
              <button type="button" onClick={onClose}>CANCEL</button>
              <button type="submit">YES</button>
            </div>
          </form>
        ) : (
        // <h2>Edit User Status</h2>
        // <button className="close-button" onClick={onClose}>×</button>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="isActive">Is Active</label>
            <select
              id="isActive"
              name="isActive"
              value={isActive}
              onChange={(e) => setIsActive(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="button-group">
            <button type="button" onClick={onClose}>CANCEL</button>
            <button type="submit">SAVE</button>
          </div>
        </form>
        )}
      </div>
    </div>
  </> // added to send email on successful reset password by admin
  );
};

export default EditUserModal;
