import React, { useState, useEffect } from 'react';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { useUser } from '../App';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

const Login = () => {
    const [userName, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const navigate = useNavigate();
    const { login } = useUser(); // Get the login function from context

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if(user.userRole === 'admin'){
                navigate('/dashboard');
            }
            else{
                navigate('/userForms');
            }
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'username') setUsername(value);
        if (name === 'password') setPassword(value);
    }

    const handlePasswordVisibilityToggle = () => {
        setPasswordShown(!passwordShown);
    }

    const fetchPostApi = (endpoint, jsonBody) => {
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
        };

        return fetch(`${config.API_BASE_URL}` + endpoint, {
            body: JSON.stringify(jsonBody),
            mode: 'cors',
            method: 'POST',
            headers: headers
        })
        .then(res => res)
        .catch(err => err);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormDisabled(true);

        // Encrypt the password before sending it
        const encryptedPassword = CryptoJS.SHA256(password).toString();

        const obj = { userName, password: encryptedPassword };
        const response = await fetchPostApi('/user/signin', obj);
        if (response.status === 200) {
            const res = await response.json();
            Swal.fire({
                title: 'Success!',
                text: 'User Login Successful',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    const userData = {
                        userRole: res.data.user.role,
                        username: res.data.user.userName,
                        userId: res.data.user.id,
                        companyId: res.data.user.companyId,
                        firstName: res.data.user.firstName,
                        lastName: res.data.user.lastName,
                        token: res.token, // Store the JWT token received from the backend in userData
                        companyDefPass: res.data.user.companyDefaultPassword // added for reset to def password by admin
                    };
                    localStorage.setItem('user', JSON.stringify(userData));
                    localStorage.setItem('loginTime', Date.now()); // Setting login time in milliseconds
                    login(userData); // Call the login function from context

                    // Redirect based on user role
                    if (userData.userRole === 'admin') {
                        navigate('/dashboard');
                    } else {
                        navigate('/userForms');
                    }
                }
            });
        } else {
            // Handle different server responses
            if (response.status === 401) {
                Swal.fire({
                    title: 'Failed!',
                    text: "Username or password is incorrect",
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
            else if (response.status === 402) {
                Swal.fire({
                    title: 'Failed!',
                    text: "This account is Disabled! Please contact Admin!",
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
            else if (response.status === 403) {
                Swal.fire({
                    title: 'Failed!',
                    text: "This account is not a part of any company! Please Contact Admin!",
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }else {
                Swal.fire({
                    title: 'Error!',
                    text: "Server not working",
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
            setFormDisabled(false);
        }
    }

    return (
        <div id="loginModal" className="login-modal">
            <div className="login-modal-content">
                <form id="loginForm" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Enter your username"
                            value={userName}
                            onChange={handleChange}
                            disabled={formDisabled}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type={passwordShown ? 'text' : 'password'}
                            id="password"
                            name="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={handleChange}
                            disabled={formDisabled}
                            required
                        />
                    </div>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            id="showPassword"
                            checked={passwordShown}
                            onChange={handlePasswordVisibilityToggle}
                        />
                        <label htmlFor="showPassword">Show Password</label>
                    </div>
                    <input type="submit" className="login-button" value="Login" disabled={formDisabled} />
                </form>
            </div>
        </div>
    );
}

export default Login;
