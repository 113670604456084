// src/UnderConstruction.js

import React from 'react';
import styled from 'styled-components';

const ConstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: white;
  color: #333;
  font-family: Arial, sans-serif;
`;

const Logo = styled.img`
  width: 300px;
  height: auto;
  margin-bottom: 20px;
`;

const Message = styled.h1`
  font-size: 2.5em;
  margin-bottom: 10px;
`;

const SubMessage = styled.p`
  font-size: 1.2em;
  color: #666;
`;

const UnderConstruction = () => {
  return (
    <ConstructionWrapper>
      <Logo src="./images/underconst.jpg" alt="Logo" />
      <Message>This page is under construction</Message>
      <SubMessage>We are working hard to give you a better experience.</SubMessage>
    </ConstructionWrapper>
  );
};

export default UnderConstruction;
