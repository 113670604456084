import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert
import Header from './shared/Header';
import Banner from './shared/Banner';
import Footer from './shared/Footer';
import HomePage from './Homepage';
import Registration from './components/Registration';
import UserProfile from './components/UserProfile';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import JotForms from './components/users/Jotforms';
import UnderConstruction from './UnderConstruction';
import Management from './components/Management';
import ProtectedRoute from './ProtectedRoute';
import FormsHome from './components/FormsHome';
import ReportsHome from './components/ReportsHome';
import ShowReports from './components/ShowReports';
import ShowAllReports from './components/ShowAllReports';

// Create a context for user state
const UserContext = createContext(null);

// Custom hook to use the UserContext
export const useUser = () => {
    return useContext(UserContext);
};

// Component to handle dynamic form routes from FormsHome page for each form card button click
const DynamicFormRoute = () => {
    const location = useLocation();
    const { form } = location.state || {};

    return form ? (
        // Render JotForms component with form data from FormsHome page for each form card button click
        <JotForms formId={form.id} formTitle={form.formName} formUrl={form.formLink} />
    ) : (
        // Redirect to homepage if form data is not found
        <Navigate to="/" />
    );
};

const TokenExpiryCheck = ({ logout }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkTokenAge = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                const loginTime = parseInt(localStorage.getItem('loginTime'), 10);
                const currentTime = Date.now();
                const diffDays = Math.floor((currentTime - loginTime) / (1000 * 60 * 60 * 24));
                if (diffDays > 90) { // Set expiry time to 90 days
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        logout();
                        navigate('/');
                    });
                }
            }
        };

        checkTokenAge();
        const intervalId = setInterval(checkTokenAge, 24 * 60 * 60 * 1000); // Check every 24 hours
        return () => clearInterval(intervalId);
    }, [logout, navigate]);

    return null; // This component doesn't render anything
};

function App() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

    // Store user data in local storage
    const login = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    };

    const logout = () => {
        setUser(null);
        // Clear local storage on logout
        localStorage.clear();
    };

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            <Router>
                <div className="App">
                    <Header />
                    <Banner />
                    <main className="content-wrapper">
                        <TokenExpiryCheck logout={logout} />
                        <Routes>
                            {/* Redirect any unmatched routes to the homepage */}
                            <Route path="*" element={<Navigate to="/" />} />
                            <Route path="/" element={<HomePage />} />
                            <Route path="/index" element={<HomePage />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Registration />} />
                            <Route path="/userProfile" element={<ProtectedRoute roleRequired="user"><UserProfile role="user" /></ProtectedRoute>} />
                            <Route path="/adminProfile" element={<ProtectedRoute roleRequired="admin"><UserProfile role="admin" /></ProtectedRoute>} />
                            <Route path="/dashboard" element={<ProtectedRoute roleRequired="admin"><Dashboard /></ProtectedRoute>} />
                            <Route path="/docs/contact" element={<JotForms formId="241275195641155" formTitle="General Inquiry Contact Form" formUrl="https://form.jotform.com/241275195641155" />} />
                            <Route path="/underConstruction" element={<UnderConstruction />} />
                            <Route path="/management" element={<ProtectedRoute roleRequired="admin"><Management /></ProtectedRoute>} />
                            <Route path="/userForms" element={<ProtectedRoute><FormsHome /></ProtectedRoute>} />
                            <Route path="/reportsHome" element={<ProtectedRoute roleRequired="admin"><ReportsHome /></ProtectedRoute>} />
                            <Route path="/forms/:formName" element={<DynamicFormRoute />} />
                            <Route path="/showReports" element={<ProtectedRoute roleRequired="admin"><ShowReports /></ProtectedRoute>} />
                            <Route path="/allReports" element={<ProtectedRoute roleRequired="admin"><ShowAllReports /></ProtectedRoute>} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </Router>
        </UserContext.Provider>
    );
}

export default App;
