import React, { useState, useEffect, useCallback } from 'react';
import '../assets/css/style.css';
import { useUser } from '../App';
import { useNavigate } from 'react-router-dom';
import config from '../config';

function FormsHome() {
    const { user } = useUser();
    const token = user.token;
    const [forms, setForms] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const fetchForms = useCallback(async () => {
        try {
            const response = await fetch(`${config.API_BASE_URL}/form`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    setForms(data.data.forms);
                }
            } else {
                console.error('Failed to fetch forms:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching forms:', error);
        } finally {
            setIsLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchForms();
    }, [fetchForms]);

    const handleFormClick = (form) => {
        const uId = user.userId; //added to pass uid in url param to fill jotform hidde fielc
        //console.log("userId is->", uId);
        navigate(`/forms/${form.formName}`, { state: { form, uId } }); //added uId to pass uid in url param to fill jotform hidde fielc
    };

    const handleBackClick = () => {
        navigate('/dashboard');
    };

    return (
        <>
            <div className="container mt-3">
                {isLoading ? (
                    <p>Loading...</p>
                ) : forms.length === 0 ? (
                    <p className="text-danger font-weight-bold">No Forms found for this Company. Please contact admin!</p>
                ) : (
                    <div className="row">
                        {forms.map((form) => (
                            <div className="col-md-4 mb-4" key={form.id}>
                                <div className="card h-100" onClick={() => handleFormClick(form)} style={{ cursor: 'pointer' }}>
                                    <div className="card-body">
                                        <h5 className="card-title text-center font-weight-bold" style={{ fontSize: '1.5rem' }}>{form.formName}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {/* <div className="text-center mt-4">
                  {user.userRole === 'admin' && (
                        <button className="btn btn-secondary" onClick={handleBackClick}>Back to Dashboard</button>
                  )}
                </div> */}
            </div>
        </>
    );
}

export default FormsHome;
