import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../assets/css/style.css';
import '../components/Reports.css';
import { useUser } from '../App'; // Import the custom hook from App.js
import config from '../config';
import Swal from 'sweetalert2';

function ShowReport() {
  const location = useLocation();
  const navigate = useNavigate();
  const { formData, formId } = location.state;
  const { user } = useUser(); // Get the user object from context
  const token = user.token; // Get the JWT token from the user object
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    if (formData.length === 0) {
      setNoData(true);
    }
    setLoading(false);
  }, [formData]);

  const handleBackClick = () => {
    navigate('/reportsHome');
  };

  const handleLaunchStreamlit = () => {
    // Get parameters from local state or user object for sending it to streamlit app
    const formID = formId;
    const companyID = user.companyId; // Replace with actual source of companyID
    const userID = user.userId; // Replace with actual source of userID

    const streamlitUrl = `${config.STREAMLIT_BASE_URL}/?formid=${formID}&companyid=${companyID}&userId=${userID}`;
    window.open(streamlitUrl, '_blank');
  };

  const getReportTitle = () => {
    switch (formId) {
      case 1: 
        return 'DVIR Report Details';
      case 2:
        return 'Field Ticket Report Details';
      case 3:
          return 'Job Safety Report Details';
      case 4:
        return 'Fertilizer Report Details';

      default:
        return 'Report Details';
    }
  };

  const getTableHeaders = () => {
    switch (formId) {
      case 1:
        return [
          'ID', 'Nix Form ID', 'User ID', 'Inspected Items List', 'Repairs Needed List', 'Trailer Inspection List', 
          'Comments', 'Form ID', 'Is Good Condition', 'Job Number', 'Odometer Reading', 'Submission ID'];
      case 3:
            //return ['ID', 'Site Task', 'Work Permit', 'Chemical Hazard', 'Mitigate Chemical Hazard', 'Physical Hazard', 'Mitigate Physical Hazard', 'Environmental Hazard', 'Mitigate Environmental Hazard', 'Other Job', 'Potential Hazard', 'Mitigate Potential Hazard', 'Equipment', 'Special Equipment', 'Created By', 'Created At', 'Updated At', 'Is Active'];
            return [
              'ID', 'Nix Form Id', 'User Id', 'Chemical Hazard Description', 'Chemical Hazard Mitigation', 
              'Physical Hazard Description', 'Physical Hazard Mitigation', 'Environmental Hazard Description', 
              'Environmental Hazard Mitigation', 'Other Jobs', 'Other Hazard Description', 
              'Other Hazard Mitigation', 'Standard PPE', 'Special PPE', 'Formid', 'Job Tasks', 
              'Additional Work', 'Submission Id'
            ];
        case 4:
        //return ['ID', 'Employee ID', 'Date', 'Product Type', 'Gallons Used', 'Field Name', 'Comment', 'Created By', 'Created At', 'Updated At', 'Is Active'];
        return ['ID', 'Employee ID', 'Date', 'Product Type', 'Gallons Used', 'Field Name', 'Comment', 'Is Active'];
     default:
        return [];
    }
  };

  const getTableRowData = (form) => {
    switch (formId) {
      case 1:
        return [
          form.ID, form.NIX_FORM_ID, form.USER_ID, form.INSPECTED_ITEMS_LIST, form.REPAIRS_NEEDED_LIST, 
          form.TRAILER_INSPECTION_LIST, form.COMMENTS, form.FORMID, form.ISGOODCONDTION.toString(), 
          form.JOBNUMBER, form.ODOMETERREADING, form.SUBMISSION_ID
        ];
      case 3:
          //return [form.id, form.siteTask, form.workPermit, form.chemicalHazard, form.mitigateChemicalHazard, form.physicalHazard, form.mitigatePhysicalHazard, form.enviornmentHazard, form.mitigateEnviornmentHazard, form.otherJob, form.potentialHazard, form.mitigatePotentialHazard, form.equipment, form.specialEquipment, form.createdBy, form.createdAt, form.updatedAt, form.isActive.toString()];
      return [
        form.ID, form.NIX_FORM_ID, form.USER_ID, form.CHEMICAL_HAZARD_DESCRIPTION, form.CHEMICAL_HAZARD_MITIGATION, 
        form.PHYSICAL_HAZARD_DESCRIPTION, form.PHYSICAL_HAZARD_MITIGATION, form.ENVIRONMENTAL_HAZARD_DESCRIPTION, 
        form.ENVIRONMENTAL_HAZARD_MITIGATION, form.OTHER_JOBS, form.OTHER_HAZARD_DESCRIPTION, 
        form.OTHER_HAZARD_MITIGATION, form.STANDARD_PPE, form.SPECIAL_PPE, form.FORMID, form.JOB_TASKS, 
        form.ADDITIONAL_WORK, form.SUBMISSION_ID
      ];
      case 4:
        //return [form.id, form.empId, form.date, form.productType, form.gallonsUsed, form.fieldName, form.comment, form.createdBy, form.createdAt, form.updatedAt, form.isActive.toString()];
        return [form.id, form.empId, form.date, form.productType, form.gallonsUsed, form.fieldName, form.comment, form.isActive.toString()];
     default:
        return [];
    }
  };

  return (
    <div className="report-container">
      {loading ? (
        <div className="loading-modal">
          <div className="loading-spinner"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <h4>{getReportTitle()}</h4>
          {noData ? (
            <p style={{marginTop: "40px"}}className="text-danger font-weight-bold">There is No Data Available for this Report!</p>
          ) : (
          <div className="table-wrapper">
            <table className="report-table">
              <thead>
                <tr>
                  {getTableHeaders().map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {formData.map((form) => (
                  <tr key={form.id}>
                    {getTableRowData(form).map((data, index) => (
                      <td key={index}>{data}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        
      
        <button className="jotFormBackButton" onClick={handleBackClick}>
          Go Back
        </button>
        {/* added for streamlit app */}
        <button className="launchStreamlitButton" onClick={handleLaunchStreamlit}>
            Show Visualization
        </button>
        </>
        // added for streamlit app 
    )}
      </div>
  );
}

export default ShowReport;
