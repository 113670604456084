import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../App'; // Import the custom hook from App.js
import config from '../config';
import './UserProfile.css';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { getElementError } from '@testing-library/react';

const UserProfile = ({ role }) => {
  const { user } = useUser(); // Get the user object from context
  const token = user.token; // Get the JWT token from the user object
  const userName = user.username; // to store the current username and pass in query Param
  const userId = user.userId; // to store the current userId and pass in query Param for updated API calls
  const companyId = user.companyId;

  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  // addeed to edit username on Update profile -shanks
  const [loading, setLoading] = useState(false); // State to manage loading spinner
  // addeed to edit username on Update profile -shanks
  const navigate = useNavigate();

  const hasFetchedProfile = useRef(false); // Track if profile has been fetched

  useEffect(() => {
    const fetchProfile = async () => {
      if (hasFetchedProfile.current) return; // TO skip calling api again if already fetched
      hasFetchedProfile.current = true;

      try {
        const response = await fetch(`${config.API_BASE_URL}/user/getProfile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}` // Add the JWT token in the headers
          }
        });
        if (response.ok) {
          const data = await response.json();
          if (data.status === 'success') {
            const profileData = data.data.user;
            const companyData = data.data.user.company;
            setFirstName(profileData.firstName);
            setLastName(profileData.lastName);
            setEmail(profileData.email);
            setPhone(profileData.phone);
            setCompanyName(companyData.name);
            setCompanyEmail(companyData.email);
            setCompanyPhone(companyData.phone);
            setCompanyAddress(companyData.street);
            setCity(companyData.city);
            setState(companyData.state);
            setZip(companyData.zip);
            setCompanyWebsite(companyData.url);
            setUsername(profileData.userName);
            setPassword(''); // Clear password fields
            setConfirmPassword('');
          } else {
            console.log('Cannot Find User');
          }
        } else {
          console.error('Failed to fetch profile:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userName && userId) { // Ensuring that the user object and userName property are not undefined
      fetchProfile();
    } else {
      //console.log('User is not defined', 'user-->' + user , 'Uname-->' + userName, 'UId-->' + userId);
    }
  }, [user, userName, userId, token]); // Add token to the dependency array

  if (isLoading) {
    return <div className="container mt-3"><p>Loading...</p></div>;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    // addeed to edit username on Update profile -shanks
    document.getElementById('username').style.border = '1px solid #878A8C'; // Set border color to red;
    // addeed to edit username on Update profile -shanks

    switch (name) {
      case 'firstName': setFirstName(value); break;
      case 'lastName': setLastName(value); break;
      case 'email': setEmail(value); break;
      case 'phone': setPhone(value); break; // Match backend "phoneNumber"
      case 'companyPhone': if (role === 'admin') setCompanyPhone(value); break;
      case 'companyAddress': if (role === 'admin') setCompanyAddress(value); break;
      case 'city': if (role === 'admin') setCity(value); break;
      case 'state': if (role === 'admin') setState(value); break;
      case 'zip': if (role === 'admin') setZip(value); break;
      case 'companyWebsite': if (role === 'admin') setCompanyWebsite(value); break; // Match backend "companyUrl"
      case 'password': setPassword(value); break;
      case 'confirmPassword': setConfirmPassword(value); break;
      case 'username' : setUsername(value); break; // addeed to edit username on Update profile -shanks
      default: break;
    }
  }

  const handlePasswordVisibilityToggle = () => {
    setPasswordShown(!passwordShown);
  }

  const fetchPostApi = (endpoint, jsonBody) => {
    return fetch(`${config.API_BASE_URL}` + endpoint, {
      body: JSON.stringify(jsonBody),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}` // Add the JWT token in the headers
      }
    })
    .then(res => res)
    .catch(err => err);
  }

  const prependHttp = (url) => {
    if (!url) return '';

    // Ensure the URL starts with http:// or https://
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }

    // Check if the URL includes "www." after the protocol
    const protocolEndIndex = url.indexOf("//") + 2;
    if (!url.substring(protocolEndIndex).includes("www.")) {
      url = `${url.substring(0, protocolEndIndex)}www.${url.substring(protocolEndIndex)}`;
    }

    return url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisabled(true);

    setLoading(true);

    if (password !== confirmPassword) {
      setLoading(false);
      Swal.fire({
        title: 'Error!',
        text: 'Passwords do not match',
        icon: 'error',
        confirmButtonText: 'Try Again'
      });
      setFormDisabled(false);
      return;
    }

    // Format and validate the company website URL
    const formattedCompanyWebsite = prependHttp(companyWebsite);

    const userUpdateObj = {
      userId,
      firstName,
      lastName,
      email,
      phoneNumber: phone, // Match backend
      companyId,
      companyUrl: formattedCompanyWebsite, // Use the formatted and validated URL
      companyPhone,
      companyStreet: companyAddress,
      companyCity: city,
      companyState: state,
      companyZip: zip,
      companyLat: '',
      companyLong: '',
      userName : username, // addeed to edit username on Update profile -shanks
    };

    if (password && password !=="") {
      // Encrypt the password before sending it
      const encryptedPassword = CryptoJS.SHA256(password).toString();
      userUpdateObj.password = encryptedPassword;
    }

    const response = await fetchPostApi('/user/editUserProfile', userUpdateObj);
    if (response.status === 200) {
      const updatedUser = { ...user, firstName, lastName, email, phone, username }; // Assuming other details are not changed or tracked
      localStorage.setItem('user', JSON.stringify(updatedUser)); // Update local storage
      setLoading(false);
      Swal.fire({
        title: 'Success!',
        text: 'Profile updated successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          //navigate('/dashboard');
          window.location.reload(); // Refresh the entire page // addeed to edit username on Update profile -shanks
        }
      });
    } else {
      setLoading(false);
      // addeed to edit username on Update profile -shanks
      let ErrorMessage = "";
      const errorStatus = response.status;
      if(errorStatus === 401){
        ErrorMessage = "Given Username already Exists! Please try other username.";
        document.getElementById('username').style.border = '1px solid red'; // Set border color to red;
      }else{
        ErrorMessage = "Failed to update profile";
      }
      // addeed to edit username on Update profile -shanks
      Swal.fire({
        title: 'Error!',
        text: ErrorMessage, // addeed to edit username on Update profile -shanks
        icon: 'error',
        confirmButtonText: 'Try Again'
      }).then(() => {
        setFormDisabled(false);
      });
    }
  };

  return (
  <>
    {loading && (
      <div className="loading-modal">
        <div className="loading-spinner"></div>
      </div>
    )}
    <div className="template-content">
      <form id="profileForm" onSubmit={handleSubmit}>
        <h6>Personal Information</h6><br /><br />
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input type="text" id="firstName" name="firstName" placeholder="First Name" value={firstName} onChange={handleChange} disabled={formDisabled}/>
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input type="text" id="lastName" name="lastName" placeholder="Last Name" value={lastName} onChange={handleChange} disabled={formDisabled} />
        </div><br /><br />
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input type="email" id="email" name="email" placeholder="Email" value={email} onChange={handleChange} required disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input type="tel" id="phone" name="phone" placeholder="Phone Number" value={phone} onChange={handleChange} disabled={formDisabled} />
        </div><br /><br />
        <h6>Company Information</h6><br />
        <div className="form-group">
          <label htmlFor="companyName">Company Name</label>
          <input type="text" id="companyName" name="companyName" placeholder="Company Name" value={companyName} disabled className="disabled-input" />
        </div>
        <div className="form-group">
          <label htmlFor="companyEmail">Company Email</label>
          <input type="email" id="companyEmail" name="companyEmail" placeholder="Company Email" value={companyEmail} onChange={handleChange} disabled className="disabled-input" />
        </div><br /><br />
        <div className="form-group">
          <label htmlFor="companyPhone">Company Phone</label>
          <input type="tel" id="companyPhone" name="companyPhone" placeholder="Company Phone" value={companyPhone} onChange={handleChange} disabled={role !== 'admin' || formDisabled} className={role !== 'admin' || formDisabled ? 'disabled-input' : ''}/>
        </div><br /><br />
        <div className="form-group">
          <label htmlFor="companyAddress">Company Address</label>
          <input type="text" id="companyAddress" name="companyAddress" placeholder="Company Address" value={companyAddress} onChange={handleChange} disabled={role !== 'admin' || formDisabled} className={role !== 'admin' || formDisabled ? 'disabled-input' : ''}/>
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input type="text" id="city" name="city" placeholder="City" value={city} onChange={handleChange} disabled={role !== 'admin' || formDisabled} className={role !== 'admin' || formDisabled ? 'disabled-input' : ''}/>
        </div><br /><br />
        <div className="form-group">
          <label htmlFor="state">State</label>
          <input type="text" id="state" name="state" placeholder="State" value={state} onChange={handleChange} disabled={role !== 'admin' || formDisabled} className={role !== 'admin' || formDisabled ? 'disabled-input' : ''}/>
        </div>
        <div className="form-group">
          <label htmlFor="zip">Zip Code</label>
          <input type="number" id="zip" name="zip" placeholder="Zip Code" value={zip} onChange={handleChange} disabled={role !== 'admin' || formDisabled} className={role !== 'admin' || formDisabled ? 'disabled-input' : ''}/>
        </div>
        <div className="form-group">
          <label htmlFor="companyWebsite">Company Website</label>
          <input type="text" id="companyWebsite" name="companyWebsite" placeholder="Website URL" value={companyWebsite} onChange={handleChange} disabled={role !== 'admin' || formDisabled} className={role !== 'admin' || formDisabled ? 'disabled-input' : ''}/>
        </div><br /><br />
        <h6>Account Information</h6><br />
        <div className="form-group">
          <label htmlFor="username">Username</label>
          {/* addeed to edit username on Update profile -shanks */}
          <input type="text" id="username" name="username" placeholder="Username" value={username} onChange={handleChange} />
          {/* addeed to edit username on Update profile -shanks */}
        </div>
        <div className="form-group">
          <label htmlFor="password">New Password</label>
          <input type={passwordShown ? 'text' : 'password'} id="password" name="password" placeholder="New Password" value={password} onChange={handleChange} disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <input type={passwordShown ? 'text' : 'password'} id="confirmPassword" name="confirmPassword" placeholder="Confirm New Password" value={confirmPassword} onChange={handleChange} disabled={formDisabled} />
        </div>
        <div className="form-check">
            <input
                type="checkbox"
                id="showPassword"
                checked={passwordShown}
                onChange={handlePasswordVisibilityToggle}
            />
            <label htmlFor="showPassword">Show Password</label>
        </div><br /><br />
        <div className="account-creation">
          <input type="button" className="cancel" value="Cancel" onClick={() => navigate('/dashboard')} disabled={formDisabled} />
          <input type="submit" className="update" value="Update Profile" disabled={formDisabled} />
        </div>
      </form>
    </div>
    </>
  );
};

export default UserProfile;
