import React, { useState, useEffect } from 'react';
import './AddUserModal.css';
import { useUser } from '../App';
import config from '../config';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { background } from '@chakra-ui/react';


const AddUserModal = ({ isOpen, onClose, onSave }) => {
  const { user } = useUser(); // Get the user object from context
  const token = user.token; // Get the JWT token from the user object
  const companyId = user.companyId; // Get the companyId from the user object
  const secretKey = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef'); // 64 hex characters for AES-256 // addeed for reset to def password by admin


  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const displaydefpass = CryptoJS.AES.decrypt(user.companyDefPass, secretKey, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8); // addeed for reset to def password by admin
  const [loading, setLoading] = useState(false); // State to manage loading spinner //to send email to all newly added users by admin

  useEffect(() => {
    if (isOpen) {
      // Reset form fields when the modal is opened
      setUserName('');
      setPassword(displaydefpass); // addeed for reset to def password by admin
      setEmail('');
      setRole('');
      setFirstName('');
      setMiddleName('');
      setLastName('');
      setFormDisabled(false);
    }
  }, [isOpen, displaydefpass]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'userName') setUserName(value);
    if (name === 'password') setPassword(value);
    if (name === 'email') setEmail(value);
    if (name === 'role') setRole(value);
    if (name === 'firstName') setFirstName(value);
    if (name === 'middleName') setMiddleName(value);
    if (name === 'lastName') setLastName(value);
  }

  const fetchPostApi = (endpoint, jsonBody) => {
    return fetch(`${config.API_BASE_URL}` + endpoint, {
      body: JSON.stringify(jsonBody),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}` // Add the JWT token in the headers
      }
    })
    .then(async (res) => {
      const text = await res.text();
      try {
        const json = JSON.parse(text);
        if (!res.ok) {
          throw json;
        }
        return json;
      } catch (error) {
        if (!res.ok) {
          const errorMessage = text.match(/<pre>(.*?)<\/pre>/s)?.[1]?.split('<br>')[0] || 'Error saving user. Please try again.';
          throw new Error(errorMessage);
        }
        throw error;
      }
    });
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    setFormDisabled(true);

    // Encrypt the password before sending it
    const encryptedPassword = CryptoJS.SHA256(password).toString();

    const newUser = { userName, password: encryptedPassword, email, role, companyId, firstName, middleName, lastName };
    try {
      const res = await fetchPostApi('/user/adduser', newUser);
      
      //to send email to all newly added users by admin

      //console.log("RES -> ", res, res.status);
      if (res.status === 'success') {

        const maildata = { 
          userName, 
          password: user.companyDefPass, 
          email, 
          role, 
          companyId, 
          firstName, 
          lastName,
          addedByFirstName: user.firstName, // for added by user
          addedByLastName: user.lastName // for added by user
        };

        try{
          //console.log("mail details -> ", maildata)
          const mailres = await fetchPostApi('/user/sendEmail', maildata);

      if (mailres.status === 'success') {
      // Both APIs were successful

      // Success feedback with SweetAlert2
      Swal.fire({
        title: 'Success!',
        text: 'A new User is Created Successfully! Email sent successfully to the new User!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(false);
          onSave();
          onClose();
        }
      });
    } else{
      throw new Error('The email was not sent.');
      }
    }catch (emailError) {
      // User creation succeeded but email sending failed
      Swal.fire({
        title: 'Warning!',
        text: 'A new User is Created Successfully! ' + 'But The Email was not sent to the New User.',
        icon: 'warning',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(false);
          onSave();
          onClose();
          }
        });
      }
    } else {
      throw new Error('Error creating user.');
      }
    } catch (userError) {
      setLoading(false);
      console.error(userError);
      // Error feedback with SweetAlert2
      Swal.fire({
        title: 'Error!',
        text: userError.message || 'Error saving user. Please try again.',
        icon: 'error',
        confirmButtonText: 'Try Again'
      });
      setFormDisabled(false);
    }
    setLoading(false);
  }

  if (!isOpen) return null;

  return (
    //to send email to all newly added users by admin
    <> 
    {loading && (
      <div className="loading-modal">
        <div className="loading-spinner"></div>
      </div>
    )}
    {/* //to send email to all newly added users by admin */}
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add User</h2>
        <button className="close-button" onClick={onClose}>×</button>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Enter first name"
              value={firstName}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="middleName">Middle Name</label>
            <input
              type="text"
              id="middleName"
              name="middleName"
              placeholder="Enter middle name"
              value={middleName}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Enter last name"
              value={lastName}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="userName">Username <span style={{ color: 'red' }}>*</span></label>
            <input
              type="text"
              id="userName"
              name="userName"
              placeholder="Enter username"
              value={userName}
              onChange={handleChange}
              disabled={formDisabled}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password <span style={{ color: 'red' }}>*</span></label>
            <input
              type="text" // addeed for reset to def password by admin
              id="password"
              name="password"
              placeholder="Enter password"
              value={password}
              onChange={handleChange}
              disabled // addeed for reset to def password by admin
              required
              style={{backgroundColor:"#878A8C"}} // addeed for reset to def password by admin
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address <span style={{ color: 'red' }}>*</span></label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={handleChange}
              disabled={formDisabled}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role <span style={{ color: 'red' }}>*</span></label>
            <select
              id="role"
              name="role"
              value={role}
              onChange={handleChange}
              disabled={formDisabled}
              required
            >
              <option value="">Select</option>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div className="button-group">
            <button type="button" onClick={onClose} disabled={formDisabled}>CANCEL</button>
            <button type="submit" disabled={formDisabled}>SAVE</button>
          </div>
        </form>
      </div>
    </div>
    </> //to send email to all newly added users by admin
  );
}

export default AddUserModal;
