import React from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import { useUser } from '../App'; // Import the custom hook from App.js

function Footer() {
  const { user } = useUser(); // Get the user from context

  // If user exists, return null (i.e., do not render the footer)
  if (user) {
    return null;
  }

  return (
    <footer className="footer">
      <div className="mainfooter">
        <div className="elements">
          <ul>
            <p className="subtitle2">What we offer</p>
            <li className="caption"><Link to="/underConstruction">Vehicle Emissions</Link></li>
            <li className="caption"><Link to="/underConstruction">Regulatory</Link></li>
            <li className="caption"><Link to="/underConstruction">Third Party Integration</Link></li>
          </ul>
        </div>
        <div className="elements">
          <ul>
            <p className="subtitle2">About</p>
            <li className="caption"><Link to="/underConstruction">About Us</Link></li>
            <li className="caption"><Link to="/underConstruction">Testimonials</Link></li>
          </ul>
        </div>
        <div className="elements">
          <ul>
            <p className="subtitle2">Feedback</p>
            <li className="caption"><Link to="/underConstruction">Send Feedback</Link></li>
          </ul>
        </div>
        <div className="elements">
          <ul>
            <p className="subtitle2">Help</p>
            <li className="caption"><Link to="/docs/contact">Contact Us</Link></li>
            <div>
              <a href="https://drive.google.com/file/d/1AYNlEuyBmBSNqh-vnAl9pr8JVCOaO2mb/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-android" style={{ fontSize: '2.5rem', color: '#3DDC84' }}></i>
              </a>
            </div>
            <div>
              <a href="https://drive.google.com/file/d/1dyKhjiZqsY1JwRVyyYxj2TgE2ixDf2Mr/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-apple" style={{ fontSize: '3rem', color: '#000' }}></i>
              </a>
            </div>
          </ul>
        </div>
      </div>
      <div className="mobile-footer">
        <div className="mobile-section">
          <h2>Find Out More About Nix</h2>
          <div className="mobile-links">
            <Link to="/underConstruction">Vehicle Emissions</Link>
            <Link to="/underConstruction">Regulatory</Link>
            <Link to="/underConstruction">Third Party Integration</Link>
            <Link to="/underConstruction">About Us</Link>
            <Link to="/underConstruction">Testimonials</Link>
            <Link to="/underConstruction">Send Feedback</Link>
            <Link to="/docs/contact">Contact Us</Link>
          </div>
        </div>
        <div className="mobile-section">
          <h2>Connect with us for more insights</h2>
          <div className="mobile-social">
            <a href="https://www.facebook.com/people/Nixcom/61556918924668/">
              <i className="fab fa-facebook" style={{ fontSize: '2.5rem', color: '#3b5998' }}></i>
            </a>
            <Link to="/underConstruction">
              <i className="fab fa-square-x-twitter" style={{ fontSize: '2.5rem', color: '#1DA1F2' }}></i>
            </Link>
            <a href="https://www.linkedin.com/company/100918420">
              <i className="fab fa-linkedin" style={{ fontSize: '2.5rem', color: '#0077B5' }}></i>
            </a>
          </div>
        </div>
        <div className="mobile-section">
          <h2>Download Our App and Start Now</h2>
          <div className="mobile-app-links">
            <div style={{ marginRight: '20px' }}>
              <a href="https://drive.google.com/file/d/1AYNlEuyBmBSNqh-vnAl9pr8JVCOaO2mb/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-android" style={{ fontSize: '3rem', color: '#3DDC84' }}></i>
              </a>
            </div>
            <div>
              <a href="https://drive.google.com/file/d/1dyKhjiZqsY1JwRVyyYxj2TgE2ixDf2Mr/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-apple" style={{ fontSize: '3rem', color: '#000' }}></i>
              </a>
            </div>
          </div>

        </div>
        <div className="end">
          <p className="caption">Copyright © 2024, nixknows. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
