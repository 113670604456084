import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Registration.css';
import { useUser } from '../App';
import config from '../config';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js'; // Import crypto-js

const Registration = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyEmail, setCompanyEmail] = useState(''); // Added companyEmail
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const { login } = useUser(); // Get the login function from context

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'firstName': setFirstName(value); break;
      case 'lastName': setLastName(value); break;
      case 'email': setEmail(value); break;
      case 'phoneNumber': setPhoneNumber(value); break;
      case 'companyName': setCompanyName(value); break;
      case 'companyPhone': setCompanyPhone(value); break;
      case 'companyAddress': setCompanyAddress(value); break;
      case 'city': setCity(value); break;
      case 'state': setState(value); break;
      case 'zip': setZip(value); break;
      case 'companyWebsite': setCompanyWebsite(value); break;
      case 'companyEmail': setCompanyEmail(value); break; // Added companyEmail
      case 'userName': setUserName(value); break;
      case 'password': setPassword(value); break;
      case 'confirmPassword': setConfirmPassword(value); break;
      default: break;
    }
  }

  const handlePasswordVisibilityToggle = () => {
    setPasswordShown(!passwordShown);
  }

  const prependHttp = (url) => {
    if (!url) return '';

    // Ensure the URL starts with http:// or https://
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }

    // Check if the URL includes "www." after the protocol
    const protocolEndIndex = url.indexOf("//") + 2;
    if (!url.substring(protocolEndIndex).includes("www.")) {
      url = `${url.substring(0, protocolEndIndex)}www.${url.substring(protocolEndIndex)}`;
    }

    return url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisabled(true);

    if (password !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match!',
      });
      setFormDisabled(false);
      return;
    }

    // Encrypt the password before sending it
    const encryptedPassword = CryptoJS.SHA256(password).toString();

    const fetchPostApi = (endpoint, jsonBody) => {
      return fetch(`${config.API_BASE_URL}` + endpoint, {
        body: JSON.stringify(jsonBody),
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then(res => res)
      .catch(err => err);
    }

    const formattedCompanyWebsite = prependHttp(companyWebsite);

    const obj = {
        firstName,
        lastName,
        email,
        phoneNumber,
        role: 'admin',
        userName,
        password: encryptedPassword,
        companyName,
        companyUrl: formattedCompanyWebsite, // Use the formatted and validated URL
        companyPhone,
        companyStreet: companyAddress,
        companyCity: city,
        companyState: state,
        companyZip: zip,
        companyLat: '',
        companyLong: '',
        companyEmail, // Added companyEmail
    };

    const response = await fetchPostApi('/user/registerAdmin', obj);
    if (response.status === 200) {
      const res = await response.json();
      const userData = {
          userRole: res.data.user.role,
          username: res.data.user.userName,
          userId: res.data.user.id,
          companyId: res.data.user.companyId,
          firstName: res.data.user.firstName,
          lastName: res.data.user.lastName,
          token: res.token, // Store the JWT token received from the backend in userData
          companyDefPass: res.data.user.company.companyDefaultPassword // added for reset to def password by admin
      };
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('loginTime', Date.now()); // Setting login time in milliseconds
      login(userData);
      Swal.fire({
          title: 'Success!',
          text: 'Registration successful',
          icon: 'success',
          confirmButtonText: 'OK'
      }).then((result) => {
          if (result.isConfirmed) {
              navigate('/dashboard');
          }
      });
    } else {
      let errorMessage = 'Server not working';
      if (response.status === 400) {
          errorMessage = 'Error in registration';
      }
      // Using SweetAlert for showing errors
      Swal.fire({
          title: 'Error!',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'Try Again'
      });
      setFormDisabled(false);
    }
  }

  return (
    <div className="template-content">
      <form id="registrationForm" onSubmit={handleSubmit}>
        <h6>Personal Information</h6><br />
        <div className="form-group">
          <label htmlFor="firstName">First</label>
          <input type="text" id="firstName" name="firstName" placeholder="First Name" value={firstName} onChange={handleChange} disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last</label>
          <input type="text" id="lastName" name="lastName" placeholder="Last Name" value={lastName} onChange={handleChange} disabled={formDisabled} />
        </div><br /><br />
        <div className="form-group">
          <label htmlFor="email">Email Address <span className="required-asterisk">*</span></label>
          <input type="email" id="email" name="email" placeholder="johnsmith@gmail.com" value={email} onChange={handleChange} required disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input type="tel" id="phoneNumber" name="phoneNumber" placeholder="xxx-xxx-xxxx" value={phoneNumber} onChange={handleChange} disabled={formDisabled} />
        </div><br /><br /><br /><br />

        <h6>Company Information</h6><br />
        <div className="form-group">
          <label htmlFor="companyName">Company Name <span className="required-asterisk">*</span></label>
          <input type="text" id="companyName" name="companyName" placeholder="Name" value={companyName} onChange={handleChange} required disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="companyEmail">Company Email <span className="required-asterisk">*</span></label>
          <input type="email" id="companyEmail" name="companyEmail" placeholder="company@example.com" value={companyEmail} onChange={handleChange} required disabled={formDisabled} />
        </div><br /><br /> {/* Moved companyEmail field */}
        <div className="form-group">
          <label htmlFor="companyPhone">Company Phone</label>
          <input type="tel" id="companyPhone" name="companyPhone" placeholder="xxx-xxx-xxxx" value={companyPhone} onChange={handleChange} disabled={formDisabled} />
        </div><br /><br />
        <div className="form-group">
          <label htmlFor="companyAddress">Address</label>
          <input type="text" id="companyAddress" name="companyAddress" placeholder="Address" value={companyAddress} onChange={handleChange} disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input type="text" id="city" name="city" placeholder="City" value={city} onChange={handleChange} disabled={formDisabled} />
        </div><br /><br />
        <div className="form-group">
          <label htmlFor="state">State</label>
          <input type="text" id="state" name="state" placeholder="State" value={state} onChange={handleChange} disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="zip">Zip</label>
          <input type="number" id="zip" name="zip" placeholder="Zip" value={zip} onChange={handleChange} disabled={formDisabled} />
        </div><br /><br />
        <div className="form-group">
          <label htmlFor="companyWebsite">Company Website</label>
          <input type="text" id="companyWebsite" name="companyWebsite" placeholder="https://" value={companyWebsite} onChange={handleChange} disabled={formDisabled} />
        </div><br /><br /><br /><br />

        <h6>Create an Account</h6><br />
        <div className="form-group">
          <label htmlFor="username">Username <span className="required-asterisk">*</span></label>
          <input type="text" id="userName" name="userName" placeholder="UserName" value={userName} onChange={handleChange} required disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password <span className="required-asterisk">*</span></label>
          <input type={passwordShown ? 'text' : 'password'} name="password" placeholder="Password" value={password} onChange={handleChange} required disabled={formDisabled} />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password <span className="required-asterisk">*</span></label>
          <input type={passwordShown ? 'text' : 'password'} id="confirmPassword" name="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={handleChange} required disabled={formDisabled} />
        </div>
        <div className="form-check">
            <input
                type="checkbox"
                id="showPassword"
                checked={passwordShown}
                onChange={handlePasswordVisibilityToggle}
            />
            <label htmlFor="showPassword">Show Password</label>
        </div><br /><br />
        <div className="account-creation">
          <input type="button" className="cancel" value="Cancel" onClick={() => navigate('/login')} disabled={formDisabled} />
          <input type="submit" className="create" value="Create Account" disabled={formDisabled} />
        </div>
      </form>
    </div>
  );
};

export default Registration;
